import { useRouter } from 'next/router'
import React, { useEffect } from 'react'

import Logo from 'static/svg/crypto-pay-logo.svg'
import { getLanguageByCode } from 'utils/i18n/languageLocal'

export default function Home() {
  const router = useRouter()

  useEffect(() => {
    const wrongLang = router.asPath.split('/')[1]
    const langLocal = getLanguageByCode(wrongLang)

    if (langLocal && router.locale === router.defaultLocale) {
      const newUrl = router.asPath.replace(`/${wrongLang}`, `/${langLocal}`)

      router.replace(newUrl)
    }
  }, [router])

  return (
    <div className="flex h-screen items-center justify-center">
      <div>
        <Logo width="300" height="100" />
        <div className="text-center text-18">welcome to Crypto Pay!</div>
      </div>
    </div>
  )
}
